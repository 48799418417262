/**
 * Progressive Enhancements der Error Summary
 * - automatischer Fokus auf die Summary
 * - Fokus auf dem Eingabefeld nach Klick auf einen Sprunglink in der Summary
 */
(function() {
    'use strict';

    /**
     * Setze den Fokus bei DOM Ready sofort auf die Error Summary
     *
     * @param {Object} elem
     */
    function focusErrorSummary(elem) {
        elem.focus();
    }

    /**
     * Fokussiere das angesprungene Eingabefeld nach Betätigung eines Sprungklinks
     * im Error Summary mit der Liste aller fehlerhaften Formularfelder.
     *
     * Hintergrund: wir setzen den Sprunglink auf die form-group, damit nach dem Sroll-Sprung auch Label und
     * Fehlermeldung im sichtbaren Bereich des Browsers sind.
     *
     * Nutze micro-timeout, weil sonst das Fokusmanagement des Browsers noch damit beschäftigt ist,
     * den Fokus auf das Sprunglinkziel (den form-group-Container des Feldes) zu setzen, und die JS-Anweisung
     * nicht ausgeführt wird.
     *
     * @param event
     */
    function focusErroneousInput(event) {
        var id = event.currentTarget.getAttribute('href').replace(/#gba-form-row-/g,'');

        window.setTimeout(function () {
            document.getElementById(id).focus();
        }, 100);
    }

    documentReady(function() {
        var errorSummary = document.querySelector('.js-form-error-summary');

        // exit early
        if (!errorSummary) return;

        var formErrorSprunglinks = errorSummary.querySelectorAll('.js-form-error-sprunglink');

        focusErrorSummary(errorSummary);

        formErrorSprunglinks.forEach(function (link) {
            link.addEventListener('click', focusErroneousInput, false);
        });
    })

    /**
     * Helferfunktion
     *
     * @param fn
     */
    function documentReady(fn) {
        // Sanity check
        if (typeof fn !== 'function') return;

        // If the document is not "loading", it is either "interactive" (document has finished loading, but sub-resources
        // such as scripts, images, stylesheets and frames are still loading) or "complete" (the page is fully loaded) and
        // we can immediately execute the callback.
        if (document.readyState !== 'loading') {
            return fn();
        }

        // Otherwise, wait until document is loaded
        document.addEventListener('DOMContentLoaded', fn, false);
    };
})();
